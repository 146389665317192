import * as React from 'react';
import Main from './Main'
import {Box, CircularProgress, CssBaseline, Paper, ThemeProvider, Typography} from "@mui/material";
import '../../style/Dashboard.css'
import MenuHeader from "./Header/MenuHeader";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import LoginToken from "../../local_storage/LoginToken";
import Theme from "../../Theme";

async function getAllowedEmails() {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json());
}

export default function Dashboard( { setLoginToken, logoutUser, haveCalendars } ){
    const [selected, setSelected] = useState(0);
    const [eventGroup, setEventGroup] = useState(null);
    const { tokenData } = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [googleUser, setGoogleUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [allowedEmails, setAllowedEmails] = useState([]);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const {theme} = Theme();


    useEffect(() => {
        if(firstRender) {
            setGoogleUser(!!(tokenData().google_id));
            setUserEmail(tokenData().email);
            getAllowedEmails().then(result => {
                if(result.state) {
                    setAllowedEmails(result.allowed_emails);
                    setUserIsAdmin(result.allowed_emails.find(user => user.email === tokenData().email)?.is_admin === 1);
                }
                setFirstRender(false);
            }).catch(err => {
                setFirstRender(false);
            })

        }

    }, [firstRender]);


    const editEventGroup = (e) => {
        setEventGroup(e);
        setSelected(1);
    }
    if(googleUser === null) {
        return (
            <Box sx={{width:1, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress/>
            </Box>
        )
    }

    return(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className='background-dashboard'>
                <Helmet>
                    <title>Calendar - Dashboard</title>
                </Helmet>
                <MenuHeader setLoginToken={setLoginToken} logoutUser={logoutUser} selected={selected} setSelected={setSelected} haveCalendars={haveCalendars}  googleUser={googleUser} userIsAdmin={userIsAdmin}/>
                {
                    haveCalendars
                        ?
                        <Main selected={selected} eventGroup={eventGroup} editEventGroup={editEventGroup} logoutUser={logoutUser} userEmail={userEmail} allowedEmails={allowedEmails} setAllowedEmails={setAllowedEmails}/>
                        :
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography component='h4' variant='h4' sx={{color: '#FFF'}}>Please connect your default calendar.</Typography>
                        </Box>
                }

            </Box>
        </ThemeProvider>

    )
}
