import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";

export default function TimeSelect({value, changeValue, disabled, text}){

    return (
        <FormControl sx={{width:100, marginX:1}} size='small'>
            <InputLabel id="demo-simple-select-label">{text}</InputLabel>
            <Select
                sx={{fontSize: 14}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={text}
                onChange={changeValue}
                disabled={disabled}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200, // Set your desired max height here
                        },
                    },
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
            </Select>
        </FormControl>

    )
}
