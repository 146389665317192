import {Avatar, AvatarGroup, Box, Button, Divider, Grid, Tooltip, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import AddIcon from '@mui/icons-material/Add';
import '../../../style/EventTypes.css'
import Paper from "@mui/material/Paper";
import * as React from "react";
import NewTemplate from "./NewTemplate";
import '../../../style/EventTypesGroup.css'
import EventTypes2 from "./EventTypes2";
import CopyToClipboardButton from "./CopyToClipboard";
import NewGroup from "./Groups/NewGroup";
import RemoveGroup from "./Groups/RemoveGroup";
import SnackbarMessages from "./SnackbarMessages";
import EditIcon from '@mui/icons-material/Edit';
import EmbedButton from "./EmbedButton";

async function getUserTemplates(login_token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates`, {
        method: 'GET',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

async function newUserGroup(login_token, name) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name})
    }).then(data => data.json())
}

async function newUserGroupWithOtherUsers(login_token, name, addedUsers) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/with_other_users`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name, addedUsers})
    }).then(data => data.json())
}

async function removeUserGroup(login_token, group) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group`, {
        method: 'DELETE',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(group)
    }).then(data => data.json())
}


/*async function saveUserTemplates(login_token, new_template) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(new_template)
    }).then(data => data.json())
}*/

async function newUserGroupCalendar(login_token, user_calendar_id, event_group_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/add_calendar`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_calendar_id, event_group_id})
    }).then(data => data.json())
}

async function deleteUserGroupCalendars(login_token, event_group_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/delete_calendars`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({event_group_id})
    }).then(data => data.json())
}

export default function EventTypesGroup( {editEventGroup, setAllGroups} ){
    const [firstRender, setFirstRender] = useState(true);
    const [rerender, setRerender] = useState(false);
    const { tokenData, isTokenExpired, removeToken, getToken } = LoginToken();
    const [userTemplates, setUserTemplates] = useState(null);
    const [path, setPath] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackBarDetails, setSnackBarDetails] = useState(null);
    //console.log(tokenData())

    useEffect(() => {
        if(firstRender){
            getUserTemplates(getToken()?.token).then(templates => {
                console.log({templates});
                if(templates.status){
                    setUserTemplates(templates.templates);
                    setAllGroups(templates.templates);
                    setPath(templates.user_path)
                }
            })
            setFirstRender(false)
        }
    });

    useEffect(() => {
        if(rerender){
            setUserTemplates(userTemplates)
            setRerender(false)
        }
    });

    const saveUserGroup = (name) => {
        newUserGroup(getToken()?.token, name).then(result => {
            if(result.status){
                userTemplates.push({
                    group_id: result.group_id,
                    group_name: name,
                    templates: []
                });
                setUserTemplates(userTemplates);
                setRerender(true)
                setSnackBarDetails({
                    message: "Added new group.",
                    severity: "success"
                })
            } else {
                setSnackBarDetails({
                    message: result?.message ? result.message : "Error on insert.",
                    severity: "error"
                })
            }
            setOpenSnackbar(true);

        })
    }

    const saveUserGroupWithCalendars = (name, users) => {
        let token = getToken()?.token
        newUserGroupWithOtherUsers(token, name, users).then(async result => {
            if(result.status){
                let details = await Promise.all(users.map(async user => {
                    return await Promise.all( user.calendars.map (calendar => {
                        return newUserGroupCalendar(token, calendar.user_calendar_id, result.group_id).then(inserted => {
                            return inserted;
                        })
                    }))
                }))
                userTemplates.push({
                    group_id: result.group_id,
                    group_name: name,
                    templates: [],
                    details
                });
                setUserTemplates(userTemplates);
                setRerender(true)
                setSnackBarDetails({
                    message: "Added new group.",
                    severity: "success"
                })
                setFirstRender(true);
            } else {
                setSnackBarDetails({
                    message: result?.message ? result.message : "Error on insert.",
                    severity: "error"
                })
            }
            setOpenSnackbar(true);

        })
    }

    const removeGroup = (group) => {
        let index = userTemplates.findIndex(ut => ut.group_id === group.group_id);
        if (index !== -1) {
            console.log(group)
            if (group?.users_calendar?.length > 0) {
                deleteUserGroupCalendars(getToken()?.token, group.group_id).then(deleted => {
                    if(deleted.state) {
                        removeUserGroup(getToken()?.token, group).then(removed => {
                            if (removed.status) {
                                userTemplates.splice(index, 1)
                                setRerender(true)
                                setSnackBarDetails({message: "User group removed.", severity: "success"})
                            } else {
                                setSnackBarDetails({message: "Error on delete. DB error.", severity: "error"})
                            }
                        })
                    } else {
                        setSnackBarDetails({message: "Error on delete group calendars.", severity: "error"})
                    }
                })
            } else {
                removeUserGroup(getToken()?.token, group).then(removed => {
                    if (removed.status) {
                        userTemplates.splice(index, 1)
                        setRerender(true)
                        setSnackBarDetails({message: "User group removed.", severity: "success"})
                    } else {
                        setSnackBarDetails({message: "Error on delete. DB error.", severity: "error"})
                    }
                })
            }

        } else {
            setSnackBarDetails({message: "Error on delete. Group not found.", severity: "error"})
        }
        setOpenSnackbar(true);
    }

    return (
        <Box className="event-types-group">
            <NewGroup saveUserGroup={saveUserGroup} saveUserGroupWithCalendars={saveUserGroupWithCalendars}/>
            <Box >
                {
                    userTemplates
                    &&
                    userTemplates.map(template_group => <SingleGroupItem key={template_group.group_id} template_group={template_group} setUserTemplates={setUserTemplates} path={path} setRerender={setRerender} editEventGroup={editEventGroup} removeGroup={removeGroup} userTemplates={userTemplates}/>)
                }
            </Box>
            { (openSnackbar && snackBarDetails) && <SnackbarMessages open={openSnackbar} setOpen={setOpenSnackbar} message={snackBarDetails.message} severity={snackBarDetails.severity}/> }
        </Box>
    )
}

function SingleGroupItem ({template_group, editEventGroup, setRerender, path, removeGroup, setUserTemplates, userTemplates}) {
    return (
        <Paper elevation={2} sx={{margin:2, backgroundColor:"#FFF", borderRadius:2}} >
            <Box px={4} py={2} sx={{display:"flex", alignItems: 'center', position:'relative', justifyContent:'space-between'}}>
                <Box sx={{display:'flex', alignItems:'center', gap:1}}>
                    <Typography sx={{color:"#131313", fontSize:22}}>{template_group.group_name}</Typography>
                    <ConnectedUsersAvatars users_calendars={template_group?.users_calendar}/>
                    <CopyToClipboardButton color='error' text={`${window.location.origin}/${path}/${template_group.group_id}`} height={30} variant={'text'} name={'Copy Link'}/>
                    <EmbedButton color='success' text={`${window.location.origin}/${path}/${template_group.group_id}`} height={30} variant={'text'} name={'Embed'}/>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', gap:1}}>
                    <Tooltip title="Edit Event Group">
                        <EditIcon sx={{color:'#32393a', cursor:'pointer'}} onClick={() => editEventGroup(template_group)}/>
                    </Tooltip>
                    <RemoveGroup remove={removeGroup} group={template_group}/>
                </Box>
            </Box>

            <Divider/>
            <EventTypes2 path={path} group_id={template_group.group_id} templates={template_group.templates} group_name={template_group.group_name} setRerender={setRerender} setAllTemplates={setUserTemplates} allTemplates={userTemplates}/>
        </Paper>
    )
}

function ConnectedUsersAvatars ({users_calendars}) {
    if(users_calendars && users_calendars?.length > 0) {
        return (
            <AvatarGroup>
                {users_calendars.map(user =>
                    <Tooltip title={user.name} key={user.user_id}>
                        <Avatar src={user.picture} alt={user.name} sx={{height:30, width:30}}/>
                    </Tooltip>
                )}
            </AvatarGroup>
        )
    }
}
