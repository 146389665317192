import {
    Box,
    Radio,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


async function updateUser(allowed_email_id, email, is_admin, added_by) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({allowed_email_id, email, is_admin, added_by})
    }).then(data => data.json())
}

export default function AllowedEmails({allowedEmails, setAllowedEmails, userEmail}) {
    return (
        <Box >

            <Grid container spacing={2} mb={2}>
                <Grid item xs={0.5} >ID</Grid>
                <Grid item xs={4.25} >User email</Grid>
                <Grid item xs={1.5} >Admin</Grid>
                <Grid item xs={4.25} >Added by user</Grid>
                <Grid item xs={1.5} >Registered</Grid>
            </Grid>
            <Box sx={{overflow: 'auto', maxHeight:460}}>
            {
                allowedEmails.map((item, index) =>
                    <SingleAllowedEmail item={item} key={index}/>
                )
            }
            </Box>
        </Box>
    )
}

function SingleAllowedEmail({item}) {

    const [id, setId] = useState(item.allowed_email_id);
    const [email, setEmail] = useState(item.email);
    const [isAdmin, setIsAdmin] = useState(item.is_admin === 1);
    const [addedBy, setAddedBy] = useState(item.added_by);
    const [registered, setRegistered] = useState(item.registered === 1);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setId(item.allowed_email_id);
        setEmail(item.email);
        setIsAdmin(item.is_admin === 1);
        setAddedBy(item.added_by);
        setRegistered(item.registered === 1);
    }, [item]);

    useEffect(() => {
        if(update) {
            updateUser(id, email, isAdmin, addedBy).then(result => {
                if(result.state) {
                    item.email = email;
                    item.is_admin = isAdmin ? 1 : 0;
                    setError(null);
                } else {
                    setError(result.error);
                }
            }).catch(() => {
                setError("Error on email update");
            })
            setUpdate(false);
        }

    }, [update]);

    return (
        <Grid container spacing={2} mb={1} sx={{fontSize:14, position:'relative'}}>
            {
                error && <Grid item xs={12} sx={{fontSize:10, color:'red', marginBottom:-2}}>{error}</Grid>
            }

            <Grid item xs={0.5} >{id}</Grid>
            <Grid item xs={4.25} ><Email email={email} disable={registered} setEmail={setEmail} setUpdate={setUpdate}/> </Grid>
            <Grid item xs={1.5} ><IsAdmin isAdmin={isAdmin} setIsAdmin={setIsAdmin} setUpdate={setUpdate}/></Grid>
            <Grid item xs={4.25} >{addedBy ? addedBy : 'Not defined'}</Grid>
            <Grid item xs={1.5} >{registered ? 'true' : 'false'}</Grid>
            {
                !registered && <RemoveCircleOutlineIcon sx={{position:'absolute', right:0, bottom:0, fontSize:16, zIndex:1}} cursor={'pointer'} color={'error'}/>
            }

        </Grid>
    )
}

function Email({email, setEmail, disable, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(email);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(email);
    }

    const saveEmail = () => {
        setUpdate(true);
        setEmail(textValue);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{email}</Typography>
            }

            {
                !disable ?
                    !edit ?
                    <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                    <Box sx={{display: 'flex', alignItems:'center'}}>
                        <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                        <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                    </Box>
                    :
                    ""
            }

        </Box>
    )
}

function IsAdmin({isAdmin, setIsAdmin, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(isAdmin ? 'admin' : 'user');

    const saveIsAdmin = (event) => {
        setValue(event.target.value);
        setIsAdmin(event.target.value === 'admin');
        setUpdate(true);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <FormControl sx={{boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', paddingX:1, borderRadius:2}}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={saveIsAdmin}
                        >
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px' } }} value="admin" control={<Radio size="small" />} label="Admin"/>
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px' } }} value="user" control={<Radio size="small"/>} label="User"/>
                        </RadioGroup>
                    </FormControl>
                    :
                    <Typography sx={{fontSize:14}}>{isAdmin ? 'true' : 'false'}</Typography>
            }

            {
                (!edit) && <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>

            }

        </Box>
    )
}

