import React, {useState, useEffect} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';
import {Box, Button, LinearProgress, Typography} from '@mui/material';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import LoginToken from "../../../local_storage/LoginToken";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

async function getWorkingNonWorkingDays(token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/working_days`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    }).then(data => data.json())
}

async function addNonWorkingDays(token, date, start_time, end_time, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/non_working_days/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, date, start_time, end_time, user_id})
    }).then(data => data.json())
}

async function removeNonWorkingDays(token, user_working_day_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/non_working_days/remove`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, user_working_day_id})
    }).then(data => data.json())
}

let workingDays = [];

function isWorkDay(date) {
    const day = date.day();
    return !!(workingDays.find(wd => wd.day === day)); // 1 is Monday, 5 is Friday
}

// Create a localizer for Day.js
const localizer = dayjsLocalizer(dayjs);

export default function CalendarWithDetails() {

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [loading, setLoading] = useState(true);

    const { getToken, tokenData } = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [nonWorkingDays, setNonWorkingDays] = useState(null);
    const [userId, setUserId] = useState(null);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [highlight, setHighlight] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
    const [selectedDate, setSelectedDate] = useState(null);
    const [removedDates, setRemovedDates] = useState([]);
    const [addedDates, setAddedDates] = useState([]);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(24);
    const [updateError, setUpdateError] = useState(null);
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if(firstRender){
            getWorkingNonWorkingDays(getToken().token).then(days => {
                if(!userId) setUserId(tokenData().user_id)
                setNonWorkingDays(days.not_working_days);
                workingDays = days.working_days;
                console.log({selectedMonth, not_working_days: days.not_working_days})
                fetchHighlightedDays(selectedMonth, days.not_working_days);
                const remappedDays = days.not_working_days.map(item => {
                    const start = dayjs(item.date).hour(item.start_time);
                    let end = dayjs(item.date).hour(item.end_time);
                    if(item.end_time === 0) {
                        end = dayjs(item.date).hour(23).minute(59).second(59);
                    }
                    return {
                        user_notworking_id: item.user_notworking_id,
                        start: start.toDate(),
                        end: end.toDate(),
                        title: `Not Working (${start.hour() === 0 && end.hour() === 23 ? 'all day' : `${start.format('HH:mm')} - ${end.format('HH:mm')}`})`
                    };
                });
                setEvents(remappedDays)

                setLoading(false);
            })
            setFirstRender(false);
        }
    }, [firstRender]);

    const fetchHighlightedDays = (month, days) => {
        //setHighlightedDays([])
        if(!month){
            month = selectedMonth ? selectedMonth : dayjs().month();
        }
        let occupied = days.map(nw => new Date(nw.date));
        let highlighted = [0];
        occupied.forEach(occ => {
            if(occ.getMonth() === month){
                highlighted.push(occ.getDate());
            }
        })
        setHighlightedDays(highlighted);
    };


    // Handle event click
    const handleEventClick = (event) => {
        console.log({event})
        setSelectedDateTime(null);
        setSelectedEvent(event);
    };

    // Handle date/time slot selection
    const handleSelectSlot = (slotInfo) => {
        const { start, end } = slotInfo;
        start.setMinutes(0);
        if(end.getMinutes() !== 0) {
            end.setHours(end.getHours() + 1)
        }
        end.setMinutes(0);
        setSelectedDateTime({ start, end });
        setSelectedEvent(null);
        // You can perform other actions here, like creating a new event
    };

    // Handle view change
    const handleViewChange = (view) => {
        console.log(`View changed to: ${view}`);
    };

    const exitDetails = () => {
        setSelectedDateTime(null);
        setSelectedEvent(null);
    }

    // Handle view change
    const addEvent = async () => {
        setWaiting(true);
        let copy = [...events];
        let start, end;

        if(selectedDateTime.start.getHours() === 0 && selectedDateTime.start.getMinutes() === 0 && selectedDateTime.end.getHours() === 0 && selectedDateTime.end.getMinutes() === 0) {
            if(selectedDateTime.end.getDate() - selectedDateTime.start.getDate() === 1) {
                start = dayjs(selectedDateTime.start).startOf('day');
                end = dayjs(selectedDateTime.start).endOf('day');
            } else {
                let currentDate = dayjs(selectedDateTime.start).startOf('day');
                const endDate = dayjs(selectedDateTime.end).add(-1, "day").endOf('day');
                let index = -1;

                while (currentDate.isBefore(endDate)) {
                    start = currentDate;
                    end = currentDate.endOf('day');
                    //Add multiple dates
                    const formattedStart = start.format('YYYY-MM-DD 00:00:00');

                    if(index !== -1) {
                        await addNonWorkingDays(getToken().token, formattedStart, start.hour(), end.hour() === 23 ? 0 : end.hour(), userId).then(result => {
                            if (result.status) {
                                copy.push({
                                    user_notworking_id: result.added.insertId,
                                    start: start.add(-1, "day").toDate(),
                                    end: end.add(-1, "day").toDate(),
                                    title: `Not Working (${start.hour() === 0 && end.hour() === 23 ? 'all day' : `${start.format('HH:mm')} - ${end.format('HH:mm')}`})`
                                });
                            }
                        })
                    }
                    index = index +1;
                    currentDate = currentDate.add(1, 'day');
                }
                setEvents(copy);
                exitDetails();
            }

        } else {
            start = dayjs(selectedDateTime.start).minute(0);
            end = dayjs(selectedDateTime.end).minute(0);
        }

        await addNonWorkingDays(getToken().token, dayjs(selectedDateTime.start).format('YYYY-MM-DD 00:00:00'), start.hour(), end.hour() === 23 ? 0 : end.hour(), userId).then(result => {
            if(result.status) {
                copy.push({
                    user_notworking_id: result.added.insertId,
                    start: start.toDate(),
                    end: end.toDate(),
                    title: `Not Working (${start.hour() === 0 && end.hour() === 23 ? 'all day' : `${start.format('HH:mm')} - ${end.format('HH:mm')}`})`
                });
                setEvents(copy);
                exitDetails();
            }
        })
        setWaiting(false);
    };

    // Handle view change
    const removeEvent = async () => {
        setWaiting(true);
        let copy = [...events];
        let index = copy.findIndex(event => event.user_notworking_id === selectedEvent.user_notworking_id);


        if (index !== -1) {
            await removeNonWorkingDays(getToken().token, selectedEvent.user_notworking_id).then(result => {
                if(result) {
                    copy.splice(index, 1);
                    setEvents(copy); // Update the state with the modified events array
                    exitDetails(); // Call the function to exit event details (or perform other actions)
                }
            })
            setWaiting(false);

        } else {
            setWaiting(false);
        }
    };

    if(loading) {
        return (
            <Box>
                <Typography>Loading</Typography>
            </Box>
        )
    }
    return (
        <Box sx={{display:'flex', overflow:'hidden', width:1, flex:1, height:'92vh'}} p={3} mt={-5}>
            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                style={{ height: '100%', width: '100%' }}
                onSelectEvent={handleEventClick} // Handle event clicks
                onSelectSlot={handleSelectSlot} // Handle slot selection
                selectable // Make slots selectable
                onView={handleViewChange} // Handle view changes
            />
            {
                selectedEvent &&
                <Box pt={4} pb={2} px={2} sx={{width:0.2, position:'relative', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    {waiting && <LinearProgress/>}
                    <Box>
                        <Typography variant='h6' mb={2}>
                            Not Working
                        </Typography>
                        <Typography mb={2} sx={{fontWeight:500}}>{dayjs(selectedEvent.start).format("DD MMM YYYY")}</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}} >
                            <AccessTimeIcon/>
                            <Typography ml={2}>{dayjs(selectedEvent.start).format("HH:mm")}</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center'}} >
                            <Typography ml={1}>|</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center'}} mb={2}>
                            <AccessTimeFilledIcon/>
                            <Typography ml={2}>{dayjs(selectedEvent.end).format("HH:mm")}</Typography>
                        </Box>
                    </Box>

                    <Button variant={'contained'} color={"success"} onClick={removeEvent} fullWidth>Remove</Button>
                    <CloseIcon variant={'contained'}  onClick={exitDetails} sx={{position:'absolute', top:10, right:15, cursor:'pointer'}}/>
                </Box>
            }
            {
                selectedDateTime &&
                <Box pt={4} pb={2} px={2} sx={{width:0.2, position:'relative', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    {waiting && <LinearProgress/>}
                    <Box>
                        <Typography variant='h6' mb={2}>
                            Add not Working
                        </Typography>
                        <Typography mb={2} sx={{fontWeight:500}}>{dayjs(selectedDateTime.start).format("DD MMM YYYY")}</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}} >
                            <AccessTimeIcon/>
                            <Typography ml={2}>{dayjs(selectedDateTime.start).format("HH:mm")}</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center'}} >
                            <Typography ml={1}>|</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center'}} mb={2}>
                            <AccessTimeFilledIcon/>
                            <Typography ml={2}>{dayjs(selectedDateTime.end).format("HH:mm")}</Typography>
                        </Box>
                    </Box>
                    <Button variant={'contained'} color={"success"} onClick={addEvent}>Add</Button>
                    <CloseIcon variant={'contained'}  onClick={exitDetails} sx={{position:'absolute', top:10, right:15, cursor:'pointer'}}/>
                </Box>
            }

        </Box>
    );
}
