import {Box, Divider, Grid, Typography} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import '../../../style/EventTypes.css'
import Paper from "@mui/material/Paper";
import * as React from "react";
import NewTemplate2 from "./Template/NewTemplate2";
import '../../../style/Event.css'
import RemoveTemplate from "./Template/RemoveTemplate";
import SnackbarMessages from "./SnackbarMessages";
import CopyToClipboardButton from "./CopyToClipboard";
import EmbedButton from "./EmbedButton";

async function saveUserTemplates(login_token, new_template) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates`, {
        method: 'POST',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(new_template)
    }).then(data => data.json())
}

async function deleteUserTemplates(login_token, template_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates`, {
        method: 'DELETE',
        headers: {
            'Authorization': login_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({template_id})
    }).then(data => data.json())
}

export default function EventTypes2({templates, group_id, group_name, allTemplates, setAllTemplates, setRerender, path}){
    const [firstRender, setFirstRender] = useState(true);
    const { tokenData, isTokenExpired, removeToken, getToken } = LoginToken();
    const [userGroupTemplates, setUserGroupTemplates] = useState(templates);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackBarDetails, setSnackBarDetails] = useState(null);


    const addNew = (template) => {
        let newTemps = [...userGroupTemplates];
        let tem_name = userGroupTemplates.find(ut => ut.template_type_id === template.template_type_id);
        template['type_name'] = tem_name ? tem_name.type_name : (template.template_type_id === 1 ? 'Google Meet' : 'Reminder');
        newTemps.push(template)
        setUserGroupTemplates(newTemps)
    }

    const removeSelected = (template) => {

        deleteUserTemplates(getToken()?.token, template.template_id).then(result => {
            if(result.status){
                let templates = allTemplates.find(ut => ut.group_id === template.event_group)?.templates
                //console.log({template});
                let index = templates.findIndex(tmp => {
                    if (tmp?.template_id){ return tmp.template_id === template.template_id}
                    else
                        return (tmp.colour === template.colour
                            && tmp.description === template.description
                            && tmp.name === template.name
                            && tmp.duration === template.duration
                            && tmp.type_name === template.type_name
                        )
                });
                if (index > -1) { // only splice array when item is found
                    templates.splice(index, 1); // 2nd parameter means remove one item only
                } else {
                    index = userGroupTemplates.findIndex(tmp => {
                        if (tmp?.template_id){ return tmp.template_id === template.template_id}
                        else
                            return (tmp.colour === template.colour
                                && tmp.description === template.description
                                && tmp.name === template.name
                                && tmp.duration === template.duration
                                && tmp.type_name === template.type_name
                            )
                    });
                    if (index > -1) { // only splice array when item is found
                        userGroupTemplates.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }



                setUserGroupTemplates(userGroupTemplates)

                console.log(allTemplates);
                setAllTemplates(allTemplates)
                setRerender(true)
            }

            setOpenSnackbar(true);
            setSnackBarDetails(result)
            console.log(result)
        })
        //console.log({ttr: template})

    }

    /*const removeSelected = (template) => {
        console.log({template});
        const index = templates.findIndex(tmp => tmp.template_id === template.template_id);
        console.log({index})
        if (index > -1) { // only splice array when item is found
            templates.splice(index, 1); // 2nd parameter means remove one item only
        }
    }*/

    return (
        <Box className="event-types-admin">
            <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography sx={{color:"#131313", fontSize:18}}>Events:</Typography>
                <NewTemplate2 saveUserTemplates={saveUserTemplates} login_token={getToken()?.token} addNew={addNew} group_id={group_id} group_name={group_name}/>
            </Box>


            <Grid container className='grid-container' >
                {
                    userGroupTemplates
                    &&
                    userGroupTemplates.map(template => {
                        return (
                            <Grid item xs={12} sm={12} md={6} lg={4}  key={template.template_id}>
                                <Paper elevation={1} className="event-paper" sx={{backgroundColor:"#FFF"}}>
                                    <Box className='event-color' sx={{background:template.colour}}></Box>
                                    <Box className="event-details" sx={{color:'#131313'}}>
                                        <RemoveTemplate template={template} remove={removeSelected}/>
                                        <Typography sx={{fontSize:16, fontWeight:600}} mb={0.7}>{template.name}</Typography>
                                        <Typography mb={0.3}>{template.duration / 1000/60} mins</Typography>
                                        <Typography sx={{fontSize:14, fontStyle:"italic"}}>Desc: {template.description}</Typography>
                                        <Typography sx={{fontSize:14, fontStyle:"italic"}}>Type: {template.type_name}</Typography>
                                    </Box>
                                    <Divider/>
                                    <Box className='event-button-holder'>
                                        <CopyToClipboardButton color='error' text={`${window.location.origin}/${path}/${group_id}/${template.template_id}`} height={30} variant={'text'} name={'Share'}/>
                                        <EmbedButton color='success' text={`${window.location.origin}/${path}/${group_id}/${template.template_id}`} height={30} variant={'text'} name={'Embed'}/>
                                    </Box>

                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>

            { (openSnackbar && snackBarDetails) && <SnackbarMessages open={openSnackbar} setOpen={setOpenSnackbar} message={snackBarDetails.message} severity={snackBarDetails.severity}/> }
        </Box>
    )
}
